// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@use '@angular/material' as mat;

// Include the Material core styles.
@include mat.core();

// Define your custom theme using Material's palette.
$liveswitch-demo-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$liveswitch-demo-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$liveswitch-demo-warn: mat.m2-define-palette(mat.$m2-red-palette);

$liveswitch-demo-theme: mat.m2-define-light-theme((
  color: (
    primary: $liveswitch-demo-primary,
    accent: $liveswitch-demo-accent,
    warn: $liveswitch-demo-warn,
  )
));

// Apply the theme to your application.
@include mat.all-component-themes($liveswitch-demo-theme);


/* You can add global styles to this file, and also import other style files */

/* For Sweetalert Input Box */
.swal2-content .swalInput {
  width: 100%;
}

html,
body {
  height: 100%;
}
body {
  background-color: #f1f3f5;
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
}

.imageModal .modal-dialog {
  max-width: fit-content;
}

.example-container {
  width: 500px;
  height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.example-sidenav-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.example-sidenav {
  padding: 20px;
}

ngb-modal-backdrop {
  z-index: 0 !important;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
